/** @format */

import React from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import { Box, Button, CircularProgress, DialogActions, DialogContent, ListItem, Typography } from '@mui/material';
import FormFields from '../form-fields/FormFields';
import { TEXT_INPUT_COMPONENT } from '../../../constants/PatientEnrollment';
import _ from 'underscore';

// Component props.
interface ConfirmationDialogProps {
  title: string;
  description: string | string[];
  isOpen: boolean;
  onClose: () => void;
  onClickConfirm?: (notes: string) => void;
  isLoading?: boolean;
  disabled?: boolean;
}

const ConfirmationDialogWithNotesComponent: React.FC<ConfirmationDialogProps> = ({ title, description, isOpen, onClose, onClickConfirm, isLoading, disabled }) => {
  const [notes, setNotes] = React.useState<string>('');
  const [notesError, setNotesError] = React.useState<string>('');

  const [isFormSubmitted, setIsFormSubmitted] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (isFormSubmitted) {
      getNotesError();
    }
  }, [isFormSubmitted, notes]);

  const getNotesError = () => {
    if (_.isEmpty(notes)) {
      setNotesError('Notes cannot be empty');
      return;
    }

    if (!_.isEmpty(notes) && notes?.length > 200) {
      setNotesError('Notes should not exceed 200 char');
      return;
    }

    setNotesError('');
  };

  const handleClickSubmit = () => {
    setIsFormSubmitted(true);
    getNotesError();

    onClickConfirm?.(notes);
  };

  return (
    <Dialog maxWidth='sm' fullWidth onClose={onClose} open={isOpen}>
      <DialogTitle variant='fontSemiBold18'>{title}</DialogTitle>
      <DialogContent>
        {Array.isArray(description) &&
          description?.map((e) => (
            <Box key={e} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <ListItem>{e}</ListItem>
            </Box>
          ))}
        {!Array.isArray(description) && <Typography sx={{ marginBottom: '20px' }}>{description}</Typography>}

        <Box component='div' mt={2}>
          <FormFields
            id='notes'
            componentType={TEXT_INPUT_COMPONENT}
            label='Notes'
            fullWidth
            input={{
              value: notes,
              onChange: (event) => setNotes(event.target.value),
              required: true,
              errorMessage: notesError,
            }}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button color='primary' variant='contained' type='reset' onClick={handleClickSubmit} disabled={isLoading || disabled}>
          {isLoading && <CircularProgress sx={{ mr: 1 }} />}
          Confirm
        </Button>
        <Button color='error' variant='outlined' onClick={onClose} sx={{ marginRight: '10px' }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialogWithNotesComponent;
