/** @format */

import axios, { CancelTokenSource } from 'axios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

// Types and interfaces.
import { RootState } from '../../app/store';
import { PatientListNewType } from '../../types/PatientListPage.types';

import { fetchPatientsList } from '../../service/patient-list';

let alertsAxiosCancelToken: CancelTokenSource | null = null;

export interface DeactivePatientListState {
  limit: number;
  pageOffset: number;

  isLoading: boolean;

  count: number;
  listData: PatientListNewType[];
}

export const FetchPatientDeactiveList = createAsyncThunk(
  'FetchPatientDeactiveList',
  async ({ limit, offset, cardiologistId }: { limit: number; offset: number; cardiologistId: string | undefined }) => {
    try {
      if (alertsAxiosCancelToken) {
        alertsAxiosCancelToken.cancel();
      }

      alertsAxiosCancelToken = axios.CancelToken.source();

      const payload = await fetchPatientsList(
        {
          status: 'DEACTIVE',
          limit,
          offset: offset * limit,

          cardiologistId,

          isEnrolmentDetails: 'false',
          isMedicalAdhrence: 'false',
          isPatientSymtoms: 'false',
          isPatientVitals: 'false',
        },
        alertsAxiosCancelToken.token,
      );

      return payload;
    } catch (err) {
      if (axios.isCancel(err)) {
        console.error(err);
      }
      throw err;
    }
  },
);

export const deactiveInitialState: DeactivePatientListState = {
  count: 0,
  listData: [],

  isLoading: true,

  limit: 20,
  pageOffset: 0,
};

export const deactivePatientListReducer = createSlice({
  name: 'Deactive patient list reducer',
  initialState: deactiveInitialState,
  reducers: {
    setPageOffset: (state, { payload }: PayloadAction<number>) => {
      state.pageOffset = Number(payload);
    },

    setLimit: (state, { payload }: PayloadAction<number>) => {
      state.pageOffset = 0;
      state.limit = Number(payload);
    },

    changeDoctorFilters: (state) => {
      state.listData = [];
      state.count = 0;
      state.pageOffset = 0;
    },

    updateDeactivePatientInfo: (state, { payload }: PayloadAction<PatientListNewType>) => {
      state.listData = [payload, ...state.listData];
    },

    clearPatientListStore: (state) => {
      state = {
        count: 0,
        listData: [],

        isLoading: false,

        limit: 20,
        pageOffset: 0,
      };
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FetchPatientDeactiveList.pending, (state: DeactivePatientListState, action) => {
      state.isLoading = true;
    });

    builder.addCase(FetchPatientDeactiveList.fulfilled, (state: DeactivePatientListState, action) => {
      state.listData = action.payload?.data;
      state.count = action.payload?.count;

      state.isLoading = false;
    });

    builder.addCase(FetchPatientDeactiveList.rejected, (state: DeactivePatientListState, action) => {
      state.isLoading = false;
    });
  },
});

export const {
  clearPatientListStore,

  updateDeactivePatientInfo,

  setPageOffset,
  setLimit,

  changeDoctorFilters,
} = deactivePatientListReducer.actions;

export const deactivePatientListSelector = (state: RootState) => state.deactivePatientList;
