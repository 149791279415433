/** @format */

import _ from 'underscore';
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import CareEditIcon from '../../assets/CardEditIcon';

import { useNavigate } from 'react-router-dom';

import CustomRangeSlider from './CustomRangeSlider';
import CustomTable from '../common/custom-table/CustomTable';

import { getVitalDisaplyLabel } from '../../utils/patient-rule';

import { CustomTableColumnType } from '../../types/CustomTable.types';
import { PatientRuleItemThresholdType } from '../../types/PatientRule.types';

import { PatientRuleConditionVitalsOptions } from '../../constants/PatientRules';
import { Box, FormControlLabel, FormGroup, Button, Checkbox, useTheme, Modal } from '@mui/material';
import Custom2PointRangeSlider from './Custom2PointRangeSlider';

interface PatientRuleThresholdProps {
  hideSaveThreshold?: boolean;

  isForceEdit?: boolean;

  defaultEdit?: boolean;

  systemDefaultAlertRuleThreshold?: PatientRuleItemThresholdType[];
  rules: PatientRuleItemThresholdType[];
  isUpdatingPatientRuleThreshold: boolean;

  handleChangeRulesThreshold?: (rule: PatientRuleItemThresholdType[]) => void;

  handleSubmitUpdatePatientRuleThreshold: (rule: PatientRuleItemThresholdType[]) => void;
}

const PatientRuleThreshold: React.FunctionComponent<PatientRuleThresholdProps> = (props) => {
  const appTheme = useTheme();

  const navigate = useNavigate();

  const prevIsUpdatingPatientRuleRef = React.useRef(props.isUpdatingPatientRuleThreshold);

  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [isEditModal, setIsEditModal] = React.useState<boolean>(false);
  const [ruleThreshold, setRuleThreshold] = React.useState<PatientRuleItemThresholdType[]>([]);

  React.useEffect(() => {
    if (props.rules) {
      setRuleThreshold(JSON.parse(JSON.stringify(props.rules)));
    }
  }, [props.rules]);

  const handleChangeConditionInputs = (value: any, variable: string, index: number) => {
    setRuleThreshold((prev) => {
      prev = prev.map((item: any, itemIndex) => {
        if (index === itemIndex) {
          item[variable] = value;
        }
        return item;
      });

      if (props.handleChangeRulesThreshold) {
        props.handleChangeRulesThreshold(prev);
      }

      return prev;
    });
  };

  const handleResetThresholdToDefault = () => {
    if (props.systemDefaultAlertRuleThreshold) {
      setRuleThreshold(props.systemDefaultAlertRuleThreshold);
      props.handleSubmitUpdatePatientRuleThreshold(props.systemDefaultAlertRuleThreshold);
    }
  };

  const handleChangeNotificationPreferenceForAllVitals = (value: any, variable: string) => {
    setRuleThreshold((prev) => {
      prev = prev.map((item: any, itemIndex) => {
        if (!item.notificationPreferences) {
          item.notificationPreferences = {};
        }
        item.notificationPreferences[variable] = value;

        return item;
      });

      if (props.handleChangeRulesThreshold) {
        props.handleChangeRulesThreshold(prev);
      }

      return prev;
    });
  };

  const handleSubmitSaveRule = () => {
    props.handleSubmitUpdatePatientRuleThreshold(ruleThreshold);
  };

  const toggleEditRule = () => {
    setIsEdit((prev) => {
      if (prev) {
        setRuleThreshold(JSON.parse(JSON.stringify(props.rules)));
      }
      if (!prev) {
        // setExpandedRule(true);
      }
      return !prev;
    });
  };

  const openIsEditModal = () => {
    setIsEditModal(true);
  };

  const closeIsEditModal = () => {
    setIsEditModal(false);
  };

  React.useEffect(() => {
    if (isEdit && prevIsUpdatingPatientRuleRef.current && !props.isUpdatingPatientRuleThreshold) {
      setIsEdit(false);
    }
    prevIsUpdatingPatientRuleRef.current = props.isUpdatingPatientRuleThreshold;
  }, [props.isUpdatingPatientRuleThreshold, isEdit, prevIsUpdatingPatientRuleRef]);

  React.useEffect(() => {
    if (props.defaultEdit) {
      setIsEdit(true);
    }
  }, [props.defaultEdit]);

  React.useEffect(() => {
    if (props.isForceEdit) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [props.isForceEdit]);

  const column: CustomTableColumnType[] = [
    {
      id: 'select-vital',
      label: '',
      cellAlignment: 'left',
      minWidth: 100,
      maxWidth: 100,
      cellFormatter: (rule: PatientRuleItemThresholdType, index: number) => {
        const options = PatientRuleConditionVitalsOptions.find((item) => item.value === rule.vitalsType);
        return (
          <Typography variant='fontSemiBold16'>
            {getVitalDisaplyLabel(rule.vitalsType)}
            <br />({options?.unit})
          </Typography>
        );
      },
    },
    {
      id: 'critical-low-and-warning-high-thresholds',
      minWidth: 350,
      maxWidth: 350,
      label: '',
      cellAlignment: 'left',
      cellFormatter: (rule: PatientRuleItemThresholdType, index: number) => {
        const options = PatientRuleConditionVitalsOptions.find((item) => item.value === rule.vitalsType);
        const sliderProps = {
          tooltipEditable: isEdit,
          disabled: !isEdit,
          minRange: options?.minRange!,
          maxRange: options?.maxRange!,
          defaultRange: options?.defaultRange!,
          value: [rule.criticalLow, rule.warningLow, rule.warningHigh, rule.criticalHigh],
          handleChangeValue: console.info,
          step: options?.step,
        };

        let Component = CustomRangeSlider;

        if (isEdit) {
          sliderProps.handleChangeValue = (value: any) => {
            handleChangeConditionInputs(value[0], 'criticalLow', index);
            handleChangeConditionInputs(value[1], 'warningLow', index);
            handleChangeConditionInputs(value[2], 'warningHigh', index);
            handleChangeConditionInputs(value[3], 'criticalHigh', index);
          };
        }

        if (rule.vitalsType === 'fluidIntake') {
          sliderProps.value = [rule.warningHigh, rule.criticalHigh];

          Component = Custom2PointRangeSlider;

          if (isEdit) {
            sliderProps.handleChangeValue = (value: any) => {
              handleChangeConditionInputs(value[0], 'warningHigh', index);
              handleChangeConditionInputs(value[1], 'criticalHigh', index);
            };
          }
        }

        return (
          <Box sx={{ paddingRight: '20px' }}>
            <Component {...sliderProps} />
          </Box>
        );
      },
    },
  ];

  const disableConditionButton = !ruleThreshold.every((item) => !_.isEmpty(item.vitalsType));

  return (
    <>
      <Box className='alerts-threshold'>
        <Grid item xs={12} container gap={2} alignItems='center' justifyContent='space-between'>
          <Grid item xs container gap={2}>
            {!isEdit && !props.hideSaveThreshold && (
              <Button disabled={props.isUpdatingPatientRuleThreshold} aria-label='Edit' onClick={openIsEditModal} size='small' variant='outlined' sx={{ gap: 0.8 }}>
                <CareEditIcon />
                <Typography variant='fontReg16'>Edit</Typography>
              </Button>
            )}

            {isEdit && !props.hideSaveThreshold && (
              <>
                <Tooltip title='Save'>
                  <Button
                    disabled={props.isUpdatingPatientRuleThreshold || disableConditionButton}
                    aria-label='Save'
                    onClick={handleSubmitSaveRule}
                    size='small'
                    variant='contained'
                    sx={{ gap: 1, height: '32px' }}>
                    <SaveIcon sx={{ fontSize: '1.3em' }} />
                    <Typography variant='fontReg16'>Save</Typography>
                  </Button>
                </Tooltip>

                <Tooltip title='Cancel'>
                  <IconButton disabled={props.isUpdatingPatientRuleThreshold} aria-label='Cancel' onClick={toggleEditRule} sx={{ height: '32px' }}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}

            {isEdit && !props.hideSaveThreshold && (
              <Button disabled={!isEdit} aria-label='Reset' onClick={handleResetThresholdToDefault} size='small' variant='outlined' sx={{ height: '32px' }}>
                <Typography variant='fontReg16'>Reset to User preset values</Typography>
              </Button>
            )}
          </Grid>

          <Grid item xs='auto' container>
            <FormGroup sx={{ display: 'block' }}>
              <Typography variant='fontReg14' color={!isEdit ? appTheme.palette.customColor.secondary : appTheme.palette.customColor.primaryLight}>
                Alert me via
              </Typography>
              <FormControlLabel
                sx={{ marginLeft: 1 }}
                control={
                  <Checkbox
                    disabled={!isEdit}
                    style={{ color: !isEdit ? 'revert-layer' : appTheme.palette.customColor.primary }}
                    checked={ruleThreshold?.every((item) => item.notificationPreferences?.email === true)}
                    onChange={(_, checked: boolean) => handleChangeNotificationPreferenceForAllVitals(checked, 'email')}
                  />
                }
                label={
                  <Typography variant='fontReg14' color={!isEdit ? appTheme.palette.customColor.secondary : appTheme.palette.customColor.primaryLight}>
                    EMAIL
                  </Typography>
                }
                labelPlacement='end'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!isEdit}
                    style={{ color: !isEdit ? 'revert-layer' : appTheme.palette.customColor.primary }}
                    checked={ruleThreshold?.every((item) => item.notificationPreferences?.sms === true)}
                    onChange={(_, checked: boolean) => handleChangeNotificationPreferenceForAllVitals(checked, 'sms')}
                  />
                }
                label={
                  <Typography variant='fontReg14' color={!isEdit ? appTheme.palette.customColor.secondary : appTheme.palette.customColor.primaryLight}>
                    SMS
                  </Typography>
                }
                labelPlacement='end'
              />
            </FormGroup>
          </Grid>
        </Grid>

        <CustomTable data={ruleThreshold} columns={column} />
      </Box>

      {isEditModal && (
        <Modal open={isEditModal} onClose={closeIsEditModal} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Grid container width={470} gap={2} sx={{ backgroundColor: '#FFFFFF', border: '1px solid #9BB4CC' }}>
            <Grid item container gap={2.5} sx={{ padding: '1rem 1rem 1rem 1rem' }}>
              <Grid item>
                <Typography variant='fontSemiBold18' color='#3E4685'>
                  Edit ?
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='fontReg14' color='#5C6A90'>
                  Please note that the values you set here are specific to this particular patient. To change default values for all patients, go to{' '}
                  <span style={{ color: '#3E4685', fontWeight: 700, cursor: 'pointer' }} onClick={() => navigate('/users/setting')}>
                    User preset
                  </span>
                </Typography>
              </Grid>
            </Grid>

            <Grid container item direction={'row'} justifyContent={'flex-end'} gap={2} pr={2} mb={2}>
              <Button onClick={() => closeIsEditModal()} size='small' variant='outlined'>
                <Typography variant='fontReg16'>Cancel</Typography>
              </Button>
              <Button onClick={() => navigate('/users/setting')} size='small' variant='outlined'>
                <Typography variant='fontReg16'>Go to User presets</Typography>
              </Button>
              <Button
                onClick={() => {
                  closeIsEditModal();
                  toggleEditRule();
                }}
                size='small'
                variant='contained'>
                <Typography variant='fontReg16'>Edit</Typography>
              </Button>
            </Grid>
          </Grid>
        </Modal>
      )}
    </>
  );
};

PatientRuleThreshold.defaultProps = {
  hideSaveThreshold: false,
  defaultEdit: false,
};

export default PatientRuleThreshold;
