/** @format */

import * as React from 'react';
import * as _ from 'underscore';
import { DateTime } from 'luxon';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListSubheader from '@mui/material/ListSubheader';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import CustomTable from '../../common/custom-table/CustomTable';
import ViewPrescriptionReportModal from './ViewPrescriptionReportModal';
import CustomLightTooltip from '../../common/custom-tooltip/CustomLightTooltip';
import { ConfirmationDialog } from '../../common/confirmation-dialog/ConfirmationDialog';
import PatientReviewStatusCell from '../../table-cell-components/PatientReviewStatusCell';
import PatientReviewActionBtnCell from '../../table-cell-components/PatientReviewActionBtnCell';
import PrescriptionReviewDateTimeCell from '../../table-cell-components/PrescriptionReviewDateTimeCell';
import PatientReviewReviewEventTypeCell from '../../table-cell-components/PatientReviewReviewEventTypeCell';
import SearchSchedulePatientAppointmentModal from '../../appointments/SearchSchedulePatientAppointmentModal';
import ConfirmationDialogWithNotesComponent from '../../common/confirmation-dialog/ConfirmationDialogWithNotes';

import { PatientProfileContext } from '../../../pages/patient-profile/PatientProfileContext';
import PrescriptionGeneratorContext from '../../../components/prescriptions-generator/PrescriptionGeneratorContext';

import { AppointmentsType } from '../../../types/Appointments';
import { CustomTableColumnType } from '../../../types/CustomTable.types';
import { PatientPrescriptionsReportType, PrescriptionGeneratorTabsType, PatientPrescriptionReviewListType, PrescriptionReviewStatusDto } from '../../../types/PrescriptionsGenerator.types';

import { downloadPatientAttachementById } from '../../../service/patient-attachments';

import { PATIENT_ACTIVE_STATUS } from '../../../constants/PatientEnrollment';

import './PatientPrescriptionSection.scss';

const customStyles = {
  backgroundColor: '#F1F2F3',
  color: '#667185',
  borderBottom: '1px solid #9BB4CC',
};

const PRESCRIPTION_REVIEW_TITLE: { [key: PrescriptionReviewStatusDto | string]: string } = {
  REJECTED: 'New Prescription',
  APPROVED: 'Prescription reviewed',
  DRAFTED: 'New Prescription',
  SUBMITTED_APPROVAL: 'Prescription awaiting approval',
  UPCOMING: 'New Prescription',
};

interface PatientPrescriptionSectionProps {
  prescriptionTabValue: PrescriptionGeneratorTabsType;
  handleTabChangePrescriptionGenerator: (event: React.SyntheticEvent, newValue: PrescriptionGeneratorTabsType) => void;
}

const PatientPrescriptionSection: React.FunctionComponent<PatientPrescriptionSectionProps> = (props) => {
  const {
    patientInfo,

    patientAlreadyPrescribedMedications,
    handleClickOnAddOrCancelPrescribedMedications,

    isPatientPrescriptionUpdating,
    handleUpdatePatientPrescriptions,

    isUpdatingDraftPatientPrescriptionReview,
    handleSubmitDraftPrescriptionsReview,

    isUpdatingSubmitApprovalPrescriptionReview,
    handleSubmitApprovalPrescriptionsReview,

    isDeletePatientPrescriptionReview,
    handleSubmitDeletePrescriptionReview,

    isRejectPatientPrescriptionReview,
    handleSubmitRejectPrescriptionReview,

    patientPrescriptionReviewList,
    prescriptionApprovalListFilters,
    handleChangePrescriptionApprovalFilters,

    isEditPatientPrescriptions,
    toggleEditPatientPrescriptions,

    handleSubmitUpdateAppointment,
    handleSubmitDeleteAppointment,
    handleSubmitNewPatientAppointment,

    patientApprovalPrescriptions,
    handleChangePatientApprovalPrescriptions,

    patientRuleThreshold,
  } = React.useContext(PatientProfileContext);

  const [isOpenDeletePrescriptionModal, setIsOpenDeletePrescriptionModal] = React.useState<boolean>(false);
  const [deletePrescriptionModalData, setDeletePrescriptionModalData] = React.useState<PatientPrescriptionReviewListType | null>(null);

  const [isOpenViewPrescriptionModal, setIsOpenViewPrescriptionModal] = React.useState<boolean>(false);
  const [viewPrescriptionModalData, setViewPrescriptionModalData] = React.useState<PatientPrescriptionReviewListType | null>(null);

  const [isOpenRejectPrescriptionModal, setIsOpenRejectPrescriptionModal] = React.useState<boolean>(false);
  const [rejectPrescriptionModalData, setRejectPrescriptionModalData] = React.useState<PatientPrescriptionReviewListType | null>(null);

  const [isOpenAppointmentRescheduleModal, setIsOpenAppointmentRescheduleModal] = React.useState<boolean>(false);
  const [appointmentRescheduleInfo, setAppointmentRescheduleInfo] = React.useState<PatientPrescriptionReviewListType | null>(null);

  const handleCloseRescheduleModel = () => {
    setAppointmentRescheduleInfo(null);
    setIsOpenAppointmentRescheduleModal(false);
  };

  const handleClickCancelEdit = () => {
    toggleEditPatientPrescriptions();

    handleClosePrescriptionConfirmationModal();
    handleChangePatientApprovalPrescriptions(null);
  };

  const handleChangeOffset = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    handleChangePrescriptionApprovalFilters(newPage, 'offset');
  };

  const handleClickDeletePatientPrescriptions = (prescriptions: PatientPrescriptionReviewListType) => {
    setIsOpenDeletePrescriptionModal(true);
    setDeletePrescriptionModalData(prescriptions);
  };

  const handleClickViewReviewReport = (prescriptions: PatientPrescriptionReviewListType) => {
    setIsOpenViewPrescriptionModal(true);
    setViewPrescriptionModalData(prescriptions);
  };

  const handleClickDownloadReport = (prescriptions: PatientPrescriptionReviewListType) => {
    if (prescriptions?.prescriptions?.pdfAttachment?.attachmentId) {
      downloadPatientAttachementById(prescriptions?.patientSourceId, prescriptions?.prescriptions?.pdfAttachment?.attachmentId).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'file.pdf'); // or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
    }
  };

  const handleCloseViewReviewReport = () => {
    setIsOpenViewPrescriptionModal(false);
    setViewPrescriptionModalData(null);
  };

  const handleClosePrescriptionConfirmationModal = () => {
    setIsOpenDeletePrescriptionModal(false);
    setDeletePrescriptionModalData(null);
    setIsOpenRejectPrescriptionModal(false);
    setRejectPrescriptionModalData(null);
  };

  const handleClickRejectPatientPrescriptions = (prescriptions: PatientPrescriptionReviewListType) => {
    setIsOpenRejectPrescriptionModal(true);
    setRejectPrescriptionModalData(prescriptions);
  };

  const handleChangeLimit = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangePrescriptionApprovalFilters(parseInt(event.target.value), 'offset');
  };

  const handleClickDraftPrescriptions = async (medications: PatientPrescriptionsReportType) => {
    await handleSubmitDraftPrescriptionsReview(medications, patientApprovalPrescriptions?.reviewId);
    toggleEditPatientPrescriptions();
  };

  const handleClickSubmitApprovalPrescription = async (medications: PatientPrescriptionsReportType) => {
    await handleSubmitApprovalPrescriptionsReview(medications, patientApprovalPrescriptions?.reviewId);
    handleClosePrescriptionConfirmationModal();
    handleChangePatientApprovalPrescriptions(null);
    toggleEditPatientPrescriptions();
  };

  const handleClickUpdatePatientPrescription = async (medications: PatientPrescriptionsReportType) => {
    if (patientApprovalPrescriptions?.reviewId) {
      medications.reviewId = patientApprovalPrescriptions?.reviewId;
    }

    await handleUpdatePatientPrescriptions(medications);
    toggleEditPatientPrescriptions();
    handleClosePrescriptionConfirmationModal();
    handleChangePatientApprovalPrescriptions(null);
  };

  const handleClickDeletePrescription = async () => {
    if (deletePrescriptionModalData?.reviewId) {
      if (!_.isEmpty(deletePrescriptionModalData?.appointmentId)) {
        await handleSubmitDeleteAppointment(deletePrescriptionModalData?.appointmentInfo!);
      } else {
        await handleSubmitDeletePrescriptionReview(deletePrescriptionModalData?.reviewId!);
      }
      handleChangePatientApprovalPrescriptions(null);
      if (isEditPatientPrescriptions) {
        toggleEditPatientPrescriptions();
      }
    }
    handleClosePrescriptionConfirmationModal();
  };

  const handleClickRejectPrescription = async (notes: string) => {
    if (_.isEmpty(notes)) {
      return;
    }

    if (rejectPrescriptionModalData?.reviewId) {
      await handleSubmitRejectPrescriptionReview(rejectPrescriptionModalData, notes);
      handleChangePatientApprovalPrescriptions(null);
      if (isEditPatientPrescriptions) {
        toggleEditPatientPrescriptions();
      }
    }
    handleClosePrescriptionConfirmationModal();
  };

  const handleClickSubmitRescheduleAppointment = async (appointments: AppointmentsType) => {
    if (appointments?.appointmentId) {
      await handleSubmitUpdateAppointment(appointments);
    } else {
      if (appointmentRescheduleInfo?.reviewId) {
        await handleSubmitDeletePrescriptionReview(appointmentRescheduleInfo?.reviewId);
      }
      await handleSubmitNewPatientAppointment(appointments);
    }

    handleCloseRescheduleModel();
  };

  const columns: CustomTableColumnType[] = [
    {
      id: 'date-time',
      label: 'Review Date & Time',
      minWidth: '8rem',
      width: '22%',
      cellFormatter: (columnValue: PatientPrescriptionReviewListType, index: number) => <PrescriptionReviewDateTimeCell key={index} rowData={columnValue} />,
    },
    {
      id: 'type',
      label: 'Type',
      minWidth: '8rem',
      width: '22%',
      cellFormatter: (columnValue: PatientPrescriptionReviewListType, index: number) => <PatientReviewReviewEventTypeCell key={index} rowData={columnValue} />,
    },
    {
      id: 'review-status',
      label: 'Review Status',
      minWidth: '8rem',
      width: '22%',
      cellFormatter: (columnValue: PatientPrescriptionReviewListType, index: number) => <PatientReviewStatusCell key={index} rowData={columnValue} />,
    },
    {
      id: 'prescription-status',
      label: 'Prescription Status',
      minWidth: '8rem',
      width: '22%',
      cellFormatter: (columnValue: PatientPrescriptionReviewListType, index: number) => {
        let status = '-';
        let color = '#33425B';

        if (columnValue?.status === 'APPROVED') {
          status = 'Generated';
          color = '#33425B';
        } else if (columnValue?.status === 'DRAFTED') {
          status = 'Draft';
          color = '#4587F0';
        } else if (columnValue?.status === 'REJECTED') {
          status = 'Rejected';
          color = '#FD5830';
        } else if (columnValue?.status === 'SUBMITTED_APPROVAL') {
          status = 'Awaiting your approval';
          color = '#D8930E';
        }

        return (
          <Typography key={index} variant='fontSemiBold14' color={color}>
            {status}

            {columnValue?.status === 'REJECTED' && (
              <CustomLightTooltip
                title={
                  <Grid item xs={12}>
                    <ListSubheader sx={{ lineHeight: '21px', px: 0.5 }}>
                      <Typography variant='fontBold14' color='#646464'>
                        Rejection note:
                      </Typography>
                    </ListSubheader>
                    <ListItem sx={{ px: 0.5 }} key={index}>
                      <Typography variant='fontReg14' color='#646464' component='div' sx={{ width: '100%' }}>
                        {columnValue.notes}
                      </Typography>
                    </ListItem>
                  </Grid>
                }>
                <Typography sx={{ p: 0.5, ml: 2, borderRadius: 1, border: '1px solid #9BB4CC', color: '#33425B' }} variant='fontReg14'>
                  Note
                </Typography>
              </CustomLightTooltip>
            )}
          </Typography>
        );
      },
    },
    {
      id: 'action',
      label: '',
      minWidth: '13rem',
      // width: '13%',
      cellFormatter: (columnValue: PatientPrescriptionReviewListType, index: number) => {
        const handleClickDelete = () => {
          handleClickDeletePatientPrescriptions(columnValue);
        };

        const handleClickReschedule = () => {
          setIsOpenAppointmentRescheduleModal(true);
          setAppointmentRescheduleInfo(columnValue);
        };

        const handleClickEdit = () => {
          handleChangePatientApprovalPrescriptions(columnValue);
          toggleEditPatientPrescriptions();
        };

        if (columnValue.status === 'UPCOMING' && DateTime.fromJSDate(new Date(columnValue.timestamp)).startOf('day').toMillis() > DateTime.local().startOf('day').toMillis()) {
          return <PatientReviewActionBtnCell key={index} showRescheduleOption handleClickReschedule={handleClickReschedule} showDeleteOption handleClickDelete={handleClickDelete} />;
        }

        if (columnValue.status === 'APPROVED' || patientInfo?.basicDetails?.patientStatus !== PATIENT_ACTIVE_STATUS) {
          return (
            <PatientReviewActionBtnCell
              key={index}
              showDeleteOption
              disableDeleteOption
              handleClickDelete={handleClickDelete}
              showViewReview
              handleClickViewReview={() => handleClickViewReviewReport(columnValue)}
              showDownloadReview
              handleClickDownloadReview={() => handleClickDownloadReport(columnValue)}
            />
          );
        }

        if (columnValue.status === 'DRAFTED') {
          return <PatientReviewActionBtnCell key={index} showResume handleClickResumne={handleClickEdit} showDeleteOption handleClickDelete={handleClickDelete} />;
        }

        if (columnValue.status === 'SUBMITTED_APPROVAL' || columnValue.status === 'REJECTED') {
          return <PatientReviewActionBtnCell key={index} showEditReview handleClickEditReview={handleClickEdit} showDeleteOption handleClickDelete={handleClickDelete} />;
        }

        return (
          <PatientReviewActionBtnCell
            key={index}
            showStartReview
            handleClickStartReview={handleClickEdit}
            showRescheduleOption={!_.isEmpty(columnValue.appointmentId)}
            handleClickReschedule={handleClickReschedule}
            showDeleteOption
            handleClickDelete={handleClickDelete}
          />
        );
      },
    },
  ];

  const fluidIntakeRuleThrehold = patientRuleThreshold?.find((item) => item.vitalsType === 'fluidIntake');
  const fluidDefaultValue = {
    criticalHigh: fluidIntakeRuleThrehold?.criticalHigh || 2,
    warningHigh: fluidIntakeRuleThrehold?.warningHigh || 1.5,
  };

  return (
    <Box id='patient-prescription-section-container'>
      <Grid item xs={12} container alignItems='center' gap={0.5} sx={{ mb: 1, mr: 3, pr: 3, background: isEditPatientPrescriptions ? '#F1F2F3' : 'transparent' }}>
        {isEditPatientPrescriptions && (
          <Grid item xs='auto'>
            <IconButton onClick={handleClickCancelEdit}>
              <ArrowBackIcon />
            </IconButton>
          </Grid>
        )}

        {isOpenViewPrescriptionModal && (
          <ViewPrescriptionReportModal
            isOpen={isOpenViewPrescriptionModal}
            handleClose={handleCloseViewReviewReport}
            previewPdfUrl={viewPrescriptionModalData?.prescriptions?.pdfAttachment?.attamentUrl}
          />
        )}

        {isEditPatientPrescriptions && (
          <Grid item xs='auto'>
            <Typography variant='fontSemiBold16' color='#3E4685'>
              {_.isEmpty(patientApprovalPrescriptions) && 'New Prescriptions'}
              {!_.isEmpty(patientApprovalPrescriptions) && (
                <Typography component='span'>
                  <Typography component='span' sx={{ textTransform: 'capitalize' }}>
                    {patientApprovalPrescriptions?.status ? PRESCRIPTION_REVIEW_TITLE[patientApprovalPrescriptions?.status] : 'New Prescriptions'}
                  </Typography>
                  {patientApprovalPrescriptions?.timestamp && (
                    <Typography component='span' sx={{ textTransform: 'capitalize', ml: 0.5 }} color='#9B9A9A'>
                      (initiated at {DateTime.fromJSDate(new Date(patientApprovalPrescriptions?.timestamp)).toFormat('h:mm a, MMM dd, yyyy ')})
                    </Typography>
                  )}
                </Typography>
              )}
            </Typography>
          </Grid>
        )}

        {!isEditPatientPrescriptions && patientInfo?.basicDetails?.patientStatus === PATIENT_ACTIVE_STATUS && (
          <Grid item xs='auto'>
            <Button onClick={() => toggleEditPatientPrescriptions()} variant='outlined'>
              Start a New Review
            </Button>
          </Grid>
        )}
      </Grid>

      {!isEditPatientPrescriptions && (
        <Box id='presciption-approval' sx={{ mt: 1.5, '& .MuiTableCell-sizeSmall': { py: '8px !important' }, '& .MuiTableCell-sizeSmall.table-body-cell-action': { py: '0px !important' } }}>
          <CustomTable
            customStyles={customStyles}
            tablePagination={prescriptionApprovalListFilters.limit < patientPrescriptionReviewList?.count}
            page={prescriptionApprovalListFilters.offset}
            handleChangePage={handleChangeOffset}
            rowsPerPage={prescriptionApprovalListFilters.limit}
            handleChangeRowsPerPage={handleChangeLimit}
            count={patientPrescriptionReviewList?.count || 0}
            columns={columns}
            data={patientPrescriptionReviewList?.data || []}
            noDataMessage='No Prescription for Review'
            dataRowHover
            stickyHeader
            tableSize='small'
            hoverEffect={false}
          />
        </Box>
      )}

      {isEditPatientPrescriptions && (
        <Box id='prescription-container'>
          <PrescriptionGeneratorContext
            patientInfo={patientInfo}
            patientSourceId={patientInfo?.basicDetails?.patientSourceId!}
            containerHeight='65.5vh'
            tabValue={props.prescriptionTabValue}
            handleChangeTabs={props.handleTabChangePrescriptionGenerator}
            patientAlreadyPrescribedMedications={patientAlreadyPrescribedMedications}
            isPatientPrescriptionUpdating={isPatientPrescriptionUpdating}
            handleUpdatePatientPrescriptions={handleClickUpdatePatientPrescription}
            isDraftPrescriptionLoading={isUpdatingDraftPatientPrescriptionReview}
            handleSubmitDraftPrescriptions={handleClickDraftPrescriptions}
            isSubmitApprovalPrescriptionUpdating={isUpdatingSubmitApprovalPrescriptionReview}
            handleSubmitApprovalPrescriptions={handleClickSubmitApprovalPrescription}
            patientReviewPrescriptions={patientApprovalPrescriptions}
            handleClickRejectPrescriptions={handleClickRejectPatientPrescriptions}
            fluidDefaultValue={fluidDefaultValue}
            handleRemovePrescriptionsMedications={(medications) => {
              if (medications) {
                handleClickOnAddOrCancelPrescribedMedications(medications, false);
              }
            }}
          />
        </Box>
      )}

      {isOpenDeletePrescriptionModal && (
        <ConfirmationDialog
          description={[!_.isEmpty(deletePrescriptionModalData?.appointmentId) ? 'This action will also delete the appointment link to this review' : '', 'Once deleted, action cannot be undone.']}
          title='Are you sure you want to delete this Prescription Review?'
          onClickConfirm={handleClickDeletePrescription}
          isOpen={isOpenDeletePrescriptionModal}
          onClose={handleClosePrescriptionConfirmationModal}
          isLoading={isDeletePatientPrescriptionReview}
        />
      )}

      {isOpenAppointmentRescheduleModal && (
        <SearchSchedulePatientAppointmentModal
          open={isOpenAppointmentRescheduleModal}
          disableButtons={false}
          isForceEdit
          isRescheduleModal
          hideGoToPatientBtn
          isNewAppointment={_.isEmpty(appointmentRescheduleInfo?.appointmentId)}
          handleClose={handleCloseRescheduleModel}
          appointment={appointmentRescheduleInfo?.appointmentInfo}
          handleSubmitAppointment={handleClickSubmitRescheduleAppointment}
          handleDeleteAppointment={console.info}
          isUpdatingAppointment={false}
          selectedPatientInfo={patientInfo?.basicDetails}
        />
      )}

      {isOpenRejectPrescriptionModal && (
        <ConfirmationDialogWithNotesComponent
          description='You are about to reject this prescription and send it back to the care team for review'
          title=''
          onClickConfirm={handleClickRejectPrescription}
          isOpen={isOpenRejectPrescriptionModal}
          onClose={handleClosePrescriptionConfirmationModal}
          isLoading={isRejectPatientPrescriptionReview}
        />
      )}
    </Box>
  );
};

export default PatientPrescriptionSection;
