/** @format */

import axios, { CancelTokenSource } from 'axios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

// Types and interfaces.
import { RootState } from '../../app/store';
import { PatientListNewType } from '../../types/PatientListPage.types';
import { fetchPatientsList } from '../../service/patient-list';

let alertsAxiosCancelToken: CancelTokenSource | null = null;

export interface DraftPatientListState {
  limit: number;
  pageOffset: number;

  isLoading: boolean;

  count: number;
  listData: PatientListNewType[];
}

export const FetchPatientDraftList = createAsyncThunk('FetchPatientDraftList', async ({ limit, offset, cardiologistId }: { limit: number; offset: number; cardiologistId: string | undefined }) => {
  try {
    if (alertsAxiosCancelToken) {
      alertsAxiosCancelToken.cancel();
    }

    alertsAxiosCancelToken = axios.CancelToken.source();

    const payload = await fetchPatientsList(
      {
        status: 'DRAFT',
        limit,
        offset: offset * limit,
        cardiologistId,

        isEnrolmentDetails: 'false',
        isMedicalAdhrence: 'false',
        isPatientSymtoms: 'false',
        isPatientVitals: 'false',
      },
      alertsAxiosCancelToken.token,
    );

    return payload;
  } catch (err) {
    if (axios.isCancel(err)) {
      console.error(err);
    }
    throw err;
  }
});

export const draftInitialState: DraftPatientListState = {
  count: 0,
  listData: [],

  isLoading: true,

  limit: 20,
  pageOffset: 0,
};

export const draftPatientListReducer = createSlice({
  name: 'Draft patient list reducer',
  initialState: draftInitialState,
  reducers: {
    setPageOffset: (state, { payload }: PayloadAction<number>) => {
      state.pageOffset = Number(payload);
    },

    setLimit: (state, { payload }: PayloadAction<number>) => {
      state.pageOffset = 0;
      state.limit = Number(payload);
    },

    changeDoctorFilters: (state) => {
      state.listData = [];
      state.count = 0;
      state.pageOffset = 0;
    },

    updateDraftPatientInfo: (state, { payload }: PayloadAction<PatientListNewType>) => {
      state.listData = state.listData.map((item) => {
        if (item?.basicDetails?.patientSourceId === payload?.basicDetails?.patientSourceId) {
          return { ...payload };
        }
        return item;
      });
    },

    clearPatientListStore: (state) => {
      state = {
        count: 0,
        listData: [],

        isLoading: false,

        limit: 20,
        pageOffset: 0,
      };
      return state;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(FetchPatientDraftList.pending, (state: DraftPatientListState, action) => {
      state.isLoading = true;
    });

    builder.addCase(FetchPatientDraftList.fulfilled, (state: DraftPatientListState, action) => {
      state.listData = action.payload?.data;
      state.count = action.payload?.count;

      state.isLoading = false;
    });

    builder.addCase(FetchPatientDraftList.rejected, (state: DraftPatientListState, action) => {
      state.isLoading = false;
    });
  },
});

export const {
  clearPatientListStore,

  updateDraftPatientInfo,

  setPageOffset,
  setLimit,

  changeDoctorFilters,
} = draftPatientListReducer.actions;

export const DraftPatientListSelector = (state: RootState) => state.draftPatientList;
