/** @format */

import * as React from 'react';
import * as _ from 'underscore';

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

// Icons
import CloseIcon from '@mui/icons-material/Close';
import { Box, CircularProgress } from '@mui/material';
import { checkUserHasPermission } from '../../utils/user-session';
import { APP_ROLES_DOCTORS } from '../../constants/user-role';

interface PrescriptionConfirmationModalProps {
  isOpen: boolean;
  isUpdatingAPICalling: boolean;

  previewPdfUrl?: string | null;
  previewPdfUrlLoading: boolean;

  handleClose: () => void;
  handleSubmit: () => void;

  isUpdatingPrescriptionApprovalAPICalling: boolean;
  handleSubmitPrescriptionApproval: () => void;
}

const PrescriptionConfirmationModal: React.FunctionComponent<PrescriptionConfirmationModalProps> = (props) => {
  const appTheme = useTheme();

  const userHasPermission = checkUserHasPermission([APP_ROLES_DOCTORS]);

  return (
    <Dialog id='medication-critical-alerts' open={props.isOpen} maxWidth='md' fullWidth onClose={props.handleClose}>
      <DialogTitle sx={{ p: '10px 15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant='fontBold16' color={appTheme.palette.customColor.text}>
          Prescription Confirmation / Submit Approval
        </Typography>
        <IconButton size='small' onClick={props.handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ '& .custom-titration-calender': { ml: 2, pr: 3 }, '& #scroll-today': { ml: 2 }, minHeight: '30rem' }}>
        {props.previewPdfUrl && <iframe src={props.previewPdfUrl} title='Patient Prescriptions' style={{ width: '100%', height: '50rem' }} />}
        {props.previewPdfUrlLoading && (
          <Box sx={{ position: 'relative', top: '45%', left: '45%', height: '100%' }}>
            <CircularProgress />
          </Box>
        )}
      </DialogContent>

      <DialogActions sx={{ py: 4, pr: 3 }}>
        <Button color='error' onClick={props.handleClose}>
          Cancel
        </Button>

        {userHasPermission && (
          <LoadingButton
            disabled={_.isEmpty(props.previewPdfUrl) || props.isUpdatingAPICalling || props.isUpdatingPrescriptionApprovalAPICalling}
            loading={props.isUpdatingAPICalling}
            variant={(userHasPermission && 'contained') || 'outlined'}
            onClick={props.handleSubmit}>
            Approve Prescription
          </LoadingButton>
        )}

        <LoadingButton
          sx={{ ml: 2 }}
          disabled={_.isEmpty(props.previewPdfUrl) || props.isUpdatingAPICalling || props.isUpdatingPrescriptionApprovalAPICalling}
          loading={props.isUpdatingPrescriptionApprovalAPICalling}
          variant={(!userHasPermission && 'contained') || 'outlined'}
          onClick={props.handleSubmitPrescriptionApproval}>
          Submit Approval
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default PrescriptionConfirmationModal;
