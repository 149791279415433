/** @format */

import * as React from 'react';
import * as _ from 'underscore';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import { vitalsUnitConverters } from 'vitals-convertor';

import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

import PatientTrendlineWeight from './PatientTrendlineWeight';

import { RootState } from '../../../app/store';
import { BasicDetailsType } from '../../../types/Patients.types';
import { PatientVitalsReadingType } from '../../../types/PatientVitals.types';
import { PatientVitalsWeigthChangeType } from '../../../types/PatientListPage.types';

import { DeCouplePatientVitalsIntoFormValueType, deCouplePatientVitalsIntoFormValue, getAllUserSelectedDateAndValues } from '../../../utils/patient-vitals-utils';

import { USER_VITALS_UNIT_MAPPING } from '../../../constants/PatientVitals';
import { PatientPrescriptionsReportType } from '../../../types/PrescriptionsGenerator.types';

interface ViewPatientVitalWeightReportedProps {
  prescriptions: PatientPrescriptionsReportType;

  patientBasicDetails: BasicDetailsType;

  vitalsReference: PatientVitalsReadingType[];
  latestVitals: PatientVitalsReadingType[];

  weightChange?: PatientVitalsWeigthChangeType;

  type: 'WEIGHT';

  showTooltip?: boolean;
  tooltipPlacement?: 'auto' | 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top';

  showTitle?: boolean;
  title?: string;

  showUnitWithTitle?: boolean;

  showDateWithValue?: boolean;
  showDateBelowValue?: boolean;

  alignContent?: 'start' | 'center' | 'end';
}

const ViewPatientVitalWeightReported: React.FunctionComponent<ViewPatientVitalWeightReportedProps> = (props) => {
  const configState = useSelector((state: RootState) => state.config);

  const appTheme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const userUnit = configState.vitalsUnit[USER_VITALS_UNIT_MAPPING[props.type] as keyof typeof configState.vitalsUnit];

  const vitalsReference: Partial<DeCouplePatientVitalsIntoFormValueType> = React.useMemo(() => {
    return deCouplePatientVitalsIntoFormValue(props.type, props.vitalsReference || [], undefined, 'yyyy-MM-dd HH:mm:ss');
  }, [props.type, props.vitalsReference]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const { titrationCycleVitals, isCriticalDownward, isCriticalUpward, isWarningDownward, isWarningUpward } = React.useMemo(() => {
  const findSeverityOfVitals = (titrationCycleVitals: DeCouplePatientVitalsIntoFormValueType[], severity: 'CRITICAL_LOW' | 'CRITICAL_HIGH' | 'WARNING_LOW' | 'WARNING_HIGH') => {
    return titrationCycleVitals.some((ele) => ele.severity === severity);
  };

  let titrationCycleVitals: DeCouplePatientVitalsIntoFormValueType[] = [];

  if (props?.latestVitals?.length > 0) {
    titrationCycleVitals = props?.latestVitals?.reduce((acc: any[], item: any) => {
      if (item.type === props.type) {
        const vitals = {
          type: item.type,
          measurements: item.measurements,
          sourceType: item.sourceType,
        };

        const result = vitalsUnitConverters(JSON.parse(JSON.stringify(vitals)), userUnit);

        const decoupleVital = deCouplePatientVitalsIntoFormValue(props.type, JSON.parse(JSON.stringify({ ...item, ...result })));

        acc.push(decoupleVital);
      }
      return acc;
    }, [] as any);
  }

  titrationCycleVitals = titrationCycleVitals.sort(
    (a: DeCouplePatientVitalsIntoFormValueType, b: DeCouplePatientVitalsIntoFormValueType) => new Date(b.dateTimestamp).getTime() - new Date(a.dateTimestamp).getTime(),
  );

  const isCriticalDownward = findSeverityOfVitals(titrationCycleVitals, 'CRITICAL_LOW');
  const isCriticalUpward = findSeverityOfVitals(titrationCycleVitals, 'CRITICAL_HIGH');
  const isWarningUpward = findSeverityOfVitals(titrationCycleVitals, 'WARNING_HIGH');
  const isWarningDownward = findSeverityOfVitals(titrationCycleVitals, 'WARNING_LOW');

  const _renderToolTipTitle = () => {
    if (!anchorEl) {
      return null;
    }

    let treadLinedata = [];

    const LAST_7_DAYS_READING_MAP = {
      WEIGHT: 'weight',
    };

    const endDate = DateTime.now().toISO() as string;
    const startDate = DateTime.fromJSDate(new Date()).minus({ days: 6 }).toISO() as string;
    treadLinedata = getAllUserSelectedDateAndValues(startDate, endDate, titrationCycleVitals, LAST_7_DAYS_READING_MAP[props.type as keyof typeof LAST_7_DAYS_READING_MAP]);

    /* Adding Missing Dates to BLOOD_PRESSURE, HEART_RATE */
    treadLinedata = treadLinedata?.filter((item: any) => !_.isEmpty(item));

    return (
      <PatientTrendlineWeight
        id={props.type}
        data={treadLinedata}
        dryWeight={(vitalsReference.value || '-') as string}
        patientBasicDetails={props.patientBasicDetails}
        weightChange={props.weightChange}
      />
    );
  };

  let showAlertIcon = false;
  let alertIconColor = appTheme.palette.customColor.warning;

  if (isWarningDownward || isWarningUpward) {
    showAlertIcon = true;
    alertIconColor = appTheme.palette.customColor.warning;
  }

  if (isCriticalDownward || isCriticalUpward) {
    showAlertIcon = true;
    alertIconColor = appTheme.palette.customColor.danger;
  }

  let weightChange: string | number = 'NA';
  let recentVitalsDate = '';
  const recentVitalColor = '#3E4685';
  const recentVitalFontSize: any = 'fontReg14';

  if (props.weightChange?.weightChangeAfter7Days) {
    weightChange = vitalsUnitConverters(
      JSON.parse(JSON.stringify({ type: 'WEIGHT', measurements: [{ value: props.weightChange?.weightChangeAfter7Days?.value, type: 'WEIGHT', unit: 'lbs' }] })),
      userUnit,
    )?.measurements?.[0]?.value;
    recentVitalsDate = 'After 7 Days';
  }

  if (props.weightChange?.weightChangeWithIn7Days) {
    weightChange = vitalsUnitConverters(
      JSON.parse(JSON.stringify({ type: 'WEIGHT', measurements: [{ value: props.weightChange?.weightChangeWithIn7Days?.value, type: 'WEIGHT', unit: 'lbs' }] })),
      userUnit,
    )?.measurements?.[0]?.value;
    recentVitalsDate = 'Between 2 to 7 Days';
  }

  return (
    <Tooltip
      title={props.showTooltip && _renderToolTipTitle()}
      onClose={() => setAnchorEl(null)}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'transparent',
            border: 'none',
          },
        },
      }}
      enterDelay={700}
      PopperProps={{
        placement: props.tooltipPlacement,
      }}>
      <Grid
        item
        xs={12}
        container
        alignContent={props.alignContent}
        justifyContent='center'
        direction='column'
        onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
        sx={{
          '&:hover': {
            cursor: 'pointer',
          },
        }}>
        {props.showTitle && (
          <Typography component='div' color='#3E4685' variant='fontReg14' sx={{ fontSize: '15px', pb: 1 }}>
            <Grid container alignItems='center'>
              {/* <InfoOutlinedIcon sx={{ mr: 0.2, width: '18px', height: '18px' }} /> */}
              {props.title}
              {props.showUnitWithTitle && (
                <Typography variant='fontReg12' pl={0.2}>
                  ({userUnit})
                </Typography>
              )}
            </Grid>
          </Typography>
        )}

        <Typography align='center' variant={recentVitalFontSize} component='div' color='#3E4685'>
          <Grid container sx={{ color: recentVitalColor }} alignItems='center'>
            {showAlertIcon && <ReportProblemOutlinedIcon sx={{ marginRight: '5px', color: alertIconColor, fontSize: '20px' }} />}

            {!_.isNaN(parseFloat(weightChange as string)) ? weightChange : 'NA'}

            {props.showDateWithValue && recentVitalsDate && (
              <Typography variant='fontReg12' pl={1}>
                ({recentVitalsDate})
              </Typography>
            )}
          </Grid>
        </Typography>

        {props.showDateBelowValue && (
          <Typography variant='fontReg12' component='div' sx={{ mt: 1, color: appTheme.palette.customColor.secondary }}>
            {recentVitalsDate || ''}
          </Typography>
        )}
      </Grid>
    </Tooltip>
  );
};

ViewPatientVitalWeightReported.defaultProps = {
  tooltipPlacement: 'left-start',
};

export default ViewPatientVitalWeightReported;
