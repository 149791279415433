/** @format */

import React from 'react';
import { CustomObservations, MultiInputInterface, ObservationInterface } from '../common/custom-observations/CustomObservations';
import { COMPLAINTS_DURATION_OPTIONS } from '../../constants/PrescriptionGenerator';

interface ComplaintsInterface {
  handleChangeComplaints: (payload: ObservationInterface[]) => void;
  complaints: ObservationInterface[];

  contextStateName?: string;
  saveToContext?: (a: string, b: 'COMPLAINTS' | 'EXAMINATIONS' | 'DIAGNOSIS' | 'NOTES' | 'REASON_FOR_CHANGE' | 'PROCEDURES') => void;
}

const Complaints: React.FC<ComplaintsInterface> = ({ handleChangeComplaints, complaints, contextStateName, saveToContext }) => {
  const [since, setSince] = React.useState<string>('');

  const [duration, setDuration] = React.useState<string>('Days');

  const EXTENDED_OBSERVATIONS: MultiInputInterface[] = [
    { type: 'input', label: 'Since', value: since, onChange: (payload: string) => setSince(payload), inputType: 'number' },
    { type: 'select', label: '', value: duration, onChange: (payload: string) => setDuration(payload), options: COMPLAINTS_DURATION_OPTIONS, defaultValue: 'Days' },
  ];

  const handleClickEditObservations = (payload: ObservationInterface) => {
    const sinceSplit = payload.subText?.split(' ');

    if (sinceSplit?.length! > 2 && sinceSplit?.[0] === 'since') {
      setSince(sinceSplit?.[1]);
      setDuration(sinceSplit?.[2]);
    }
  };

  return (
    <CustomObservations
      observationActionTitle='Add Complaints'
      observationTitle='Complaints'
      options={[
        { label: 'Fever', value: 'FEVER' },
        { label: 'Headache', value: 'HEADACHE' },
        { label: 'Cough', value: 'COUGH' },
        /*  */
        { label: 'Shortness of breath during activity (exertional dyspnea)', value: 'SHORTNESS_OF_BREATH_DURING_ACTIVITY_EXERTIONAL_DYSPNEA' },
        { label: 'Shortness of breath at rest', value: 'SHORTNESS_OF_BREATH_AT_REST' },
        { label: 'Shortness of breath when lying down (Orthopnea)', value: 'SHORTNESS_OF_BREATH_WHEN_LYING_DOWN_ORTHOPNEA' },
        { label: 'Sudden breathlessness at night (Paroxysmal Nocturnal Dyspnea)', value: 'SUDDEN_BREATHLESSNESS_AT_NIGHT_PAROXYSMAL_NOCTURNAL_DYSPNEA' },
        { label: 'Can sleep only with headside elevated significantly', value: 'CAN_SLEEP_ONLY_WITH_HEADSIDE_ELEVATED_SIGNIFICANTLY' },
        { label: 'General fatigue even at rest', value: 'GENERAL_FATIGUE_EVEN_AT_REST' },
        { label: 'Increased fatigue during normal activity', value: 'INCREASED_FATIGUE_DURING_NORMAL_ACTIVITY' },
        { label: 'Unable to walk or perform normal activity (Exercise intolerance)', value: 'UNABLE_TO_WALK_OR_PERFORM_NORMAL_ACTIVITY_EXERCISE_INTOLERANCE' },
        { label: 'Muscle cramps', value: 'MUSCLE_CRAMPS' },
        { label: 'Swelling of legs, ankles, or feet (Peripheral edema)', value: 'SWELLING_OF_LEGS,_ANKLES,_OR_FEET_PERIPHERAL_EDEMA' },
        { label: 'Abdominal swelling (Ascites)', value: 'ABDOMINAL_SWELLING_ASCITES' },
        { label: 'Weight gain due to fluid retention', value: 'WEIGHT_GAIN_DUE_TO_FLUID_RETENTION' },
        { label: 'Swelling of lips, face and throat', value: 'SWELLING_OF_LIPS,_FACE_AND_THROAT' },
        { label: 'Dizziness while standing up quickly (orthostatic hypotension)', value: 'DIZZINESS_WHILE_STANDING_UP_QUICKLY_ORTHOSTATIC_HYPOTENSION' },
        { label: 'General Dizziness and light-headedness', value: 'GENERAL_DIZZINESS_AND_LIGHT_HEADEDNESS' },
        { label: 'Fainting (syncope)', value: 'FAINTING_SYNCOPE' },
        { label: 'Palpitations', value: 'PALPITATIONS' },
        { label: 'Chest pain or discomfort (Angina)', value: 'CHEST_PAIN_OR_DISCOMFORT_ANGINA' },
        { label: 'Chest tightness ', value: 'CHEST_TIGHTNESS ' },
        { label: 'Low heart rate', value: 'LOW_HEART_RATE' },
        { label: 'Irregular heart rate (arrhythmias)', value: 'IRREGULAR_HEART_RATE_ARRHYTHMIAS' },
        { label: 'Confusion', value: 'CONFUSION' },
        { label: 'Memory loss or difficulty concentrating', value: 'MEMORY_LOSS_OR_DIFFICULTY_CONCENTRATING' },
        { label: 'Anxiety', value: 'ANXIETY' },
        { label: 'Depression', value: 'DEPRESSION' },
        { label: 'Mood swings', value: 'MOOD_SWINGS' },
        { label: 'Loss of appetite', value: 'LOSS_OF_APPETITE' },
        { label: 'Nausea, vomitting', value: 'NAUSEA,_VOMITTING' },
        { label: 'Feeling full after eating small amounts (Early satiety)', value: 'FEELING_FULL_AFTER_EATING_SMALL_AMOUNTS_EARLY_SATIETY' },
        { label: 'Frequent urination at night (Nocturia)', value: 'FREQUENT_URINATION_AT_NIGHT_NOCTURIA' },
        { label: 'Persistent cough or wheezing with white phlegm', value: 'PERSISTENT_COUGH_OR_WHEEZING_WITH_WHITE_PHLEGM' },
        { label: 'Persistent cough or wheezing with blood-tinged phlegm', value: 'PERSISTENT_COUGH_OR_WHEEZING_WITH_BLOOD_TINGED_PHLEGM' },
        { label: 'Dry hacking cough', value: 'DRY_HACKING_COUGH' },
        { label: 'Cold hands and feet', value: 'COLD_HANDS_AND_FEET' },
        { label: 'Vision blurring', value: 'VISION_BLURRING' },
        { label: 'Sees halos around light', value: 'SEES_HALOS_AROUND_LIGHT' },
        { label: 'Sexual dysfunction', value: 'SEXUAL_DYSFUNCTION' },
      ]}
      handleChangeObservations={handleChangeComplaints}
      inputs={EXTENDED_OBSERVATIONS}
      bufferText='since'
      existingObservations={complaints}
      contextStateName={contextStateName}
      saveToContext={saveToContext}
      showHoverEffectBorderOnInputField
      handleClickEditObservations={handleClickEditObservations}
    />
  );
};

export default Complaints;
