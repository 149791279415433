/** @format */

import React from 'react';
import { FormControl, FormLabel, MenuItem, OutlinedInput, Select, Typography, useTheme } from '@mui/material';
import { DropdownOptionType } from '../../../types/CustomForm.types';

export const GridSelectInputField = (props: any) => {
  const appTheme = useTheme();
  let options: DropdownOptionType[] = [];

  if (props.uiSchema['ui:customOptions']) {
    options = props.uiSchema['ui:customOptions'];
  } else {
    options = props.options.enumOptions;
  }

  const [value, setValue] = React.useState<string>('');

  React.useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  const required = props.uiSchema['ui:required'] || false;
  const mandatory = props.uiSchema['ui:mandatory'] || false;

  const disabled = props.disabled || props.uiSchema['ui:disabled'] || false;

  return (
    <FormControl id={props.id} required={props.required} disabled={disabled}>
      <FormLabel sx={{ pb: '8px', display: 'flex', alignItems: 'center' }}>
        <Typography variant='fontSemiBold14' color={appTheme.palette.customColor.pageTitle} component='div' sx={{ marginLeft: '5px', display: 'flex', alignItems: 'center' }}>
          {props.label} {mandatory && <span style={{ marginLeft: '3px' }}>*</span>}
        </Typography>

        {required && (
          <Typography color='error' sx={{ marginLeft: '3px' }}>
            *
          </Typography>
        )}
      </FormLabel>

      <Select
        fullWidth
        inputProps={{
          tabIndex: props.uiSchema?.['ui:tabIndex'],
        }}
        disabled={disabled}
        value={value}
        tabIndex={props.uiSchema?.['ui:tabIndex']}
        onChange={(event: any) => props.onChange(event.target.value)}
        error={!!(props.rawErrors && props.rawErrors.length !== 0)}
        id={props.id}
        key={props.id}
        input={<OutlinedInput label='Hello' />}>
        {options?.map((e: DropdownOptionType) => {
          return (
            <MenuItem
              value={e.value}
              selected={props.value}
              key={e.value}
              sx={{
                maxHeight: '45px',
                padding: '12px',
              }}>
              {e.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
